<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{ name }"
        :exact="name === 'Home'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="name" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        @click="changeLocale(language)"
      >
        <v-list-item-avatar>
          <v-img
            :src="language.src"
          />
        </v-list-item-avatar>
        <v-list-item-title>
          {{ language.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    data: () => ({
      languages: [
        {
          title: 'English',
          locale: 'en',
          src: require('@/assets/flag_us.png'),
        },
        {
          title: '한국어',
          locale: 'ko',
          src: require('@/assets/flag_ko.png'),
        },
      ],
    }),
    computed: {
      fnGetLanguage () {
        return this.languages[0]
      },
    },
    methods: {
      fnGoToHome () {
        this.$router.push('/')
      },
      changeLocale (language) {
        if (language.locale === 'ko') {
          window.open('https://kr.rhrobotics.com/', '_self')
        }
      },
    },
  }
</script>
